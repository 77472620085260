define("shop/translations/ro", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "about": {
      "contact": "Contact",
      "content": {
        "caption": "Ștefan și Alexandru Gugurel au fondat Lunet în 2019\n",
        "p1": "Purtăm ochelari de mult timp, dar n-a fost mereu o experiență plăcută.\nTrăiam cu impresia că ochelarii de calitate trebuie să fie scumpi.\nCredeam că trebuie să ne limităm la oferta cam plictisitoare de la oftalmolog.\nȘi credeam că trebuie să purtăm aceiași ochelari ca toată lumea.\n",
        "p2": "Dar am avut o revelație. Modelul de business din industria oftalmologică e depășit.\nProcesul clasic are prea mulți pași. Așa că am decis ca noi să facem altfel lucrurile.\n",
        "p3": "Ne ocupăm noi înșine de design, în București. Ochelarii sunt fabricați de specialiști\nși vânduți exclusiv în magazinele proprii și pe siteul nostru. Ți-i livrăm direct, fără\na mai trece prin diverși intermediari. Astfel, reușim să reducem costurile suplimentare.\n"
      },
      "title": "Despre"
    },
    "accessories": {
      "clipOns": {
        "subtitle": "Un clip-on îți transformă ochelarii de vedere obișnuiți în ochelari de soare.",
        "title": "Clip-on"
      }
    },
    "account": {
      "appointments": {
        "title": "Consultații"
      },
      "btn": {
        "save": "Salvează",
        "signOut": "Deconectare"
      },
      "label": {
        "email": "Email-ul tău",
        "firstName": "Prenumele",
        "lastName": "Numele",
        "phone": "Telefon"
      },
      "orders": {
        "title": "Comenzi"
      },
      "prescription": {
        "label": {
          "add": "ADD",
          "axis": "AXIS",
          "cylinder": "CYL",
          "pupilDistance": "PD",
          "sphere": "SPH"
        },
        "title": "Rețeta"
      },
      "title": "Cont"
    },
    "alert": {
      "eyeTest": "Consultații gratuite!",
      "schedule": "Rezervă loc"
    },
    "appointment": {
      "contactsNotice": "Evită să te prezinți la consultație purtând lentilele de contact. Dacă nu e posibil, te rugăm să ai la tine suportul de protecție, pentru a le putea depozita în timpul consultației optometrice.",
      "description": "Am înregistrat programarea ta. Vom reveni cu un mesaj cu o zi înainte.\nConsultația durează aproximativ 20 minute. Dacă nu mai poți ajunge,\nte rugăm să ne anunți la <a class=\"font-medium\" href=\"tel:{phone}\">{phone}</a>.\n",
      "ending": "Ne vedem în curând!",
      "paymentNotice": "Te rugăm să ai în vedere că acceptăm<br>\n<strong>doar plata cu cardul</strong> în magazine.\n",
      "title": "Te-ai programat pentru consultație!"
    },
    "archive": {
      "nav": {
        "glasses": "Vedere",
        "sunglasses": "Soare"
      },
      "subtitle": "Modelele selectate din Archive Sale sunt acum la 50% reducere. Nu mai sunt multe, alege-ți noua pereche!",
      "title": "Archive Sale"
    },
    "cart": {
      "autoDiscount": "Reducere",
      "btn": {
        "addFrame": "adaugă model",
        "checkout": "Pasul următor",
        "freeCheckout": "Pasul următor (gratuit)",
        "getShopping": "Descoperă"
      },
      "delayedShipping": "Livrare în 7 - 9 zile lucrătoare",
      "discount": "Reducere ({code})",
      "empty": "Nu ai nimic în coș.<br> Începe cumpărăturile!",
      "free": "Gratuit",
      "htoCounter": "<strong>{count} din {max}</strong> modele alese",
      "htoTitle": "Home Try-on",
      "shipping": "Livrare în 3 - 5 zile lucrătoare",
      "title": "Coș",
      "total": "Total"
    },
    "category": {
      "clipOns": {
        "title": "Clip-on"
      },
      "glasses": {
        "description": "Filtrul de protecție calculator e ideal pentru oricine\npetrece mult timp în fața ecranelor. În octombrie, adaugă-l\ngratuit la orice pereche de ochelari.\n",
        "subtitle": "Alege modelul preferat. Plătești <strong>{price}</strong>\nși ai lentile cu dioptrii incluse în preț.\n",
        "title": "Ochelari de vedere"
      },
      "sunglasses": {
        "subtitle": "Alege modelul preferat. Plătești <strong>{price}</strong>\nși ai lentile cu protecție UV incluse.\n",
        "title": "Ochelari de soare"
      }
    },
    "checkout": {
      "address": {
        "label": {
          "address1": "Stradă, număr",
          "address2": "Scară, bloc, ap",
          "city": "Oraș",
          "company": "Companie",
          "companyNumber": "Număr TVA",
          "county": "Județ",
          "firstName": "Prenume",
          "lastName": "Nume",
          "phone": "Telefon",
          "zip": "Cod poștal"
        }
      },
      "billing": {
        "btn": {
          "save": "Continuă",
          "skip": "Adaugă mai târziu"
        },
        "option": {
          "differentAddress": "Adaugă o adresă nouă",
          "sameAsShipping": "Folosește adresa de livrare"
        },
        "title": "Adaugă adresa de facturare"
      },
      "confirmation": {
        "cardPayment": "Autorizare card bancar",
        "description": "Serviciul de probă acasă este gratuit. Datele cardului tău\nsunt necesare strict pentru validare. Autorizarea presupune\nblocarea temporară a unei valori minime de 1 RON.\nVei fi taxat doar dacă nu returnezi ramele către noi.\n",
        "title": "Introdu datele cardului pentru confirmare"
      },
      "index": {
        "btn": {
          "login": "Am un cont",
          "save": "Continuă"
        },
        "description": "Introdu email-ul pentru a plasa comanda.",
        "label": {
          "aggrement": "Prin plasarea comenzii ești de acord cu",
          "and": "și",
          "code": "Codul de verificare",
          "email": "Email-ul tău",
          "privacy": "politica de confidențialitate",
          "terms": "termenii și condițiile"
        },
        "notice": {
          "password": "Ți-am trimis un email cu un cod de 4 cifre."
        },
        "title": "Ești aproape de final"
      },
      "payment": {
        "btn": {
          "apply": "Aplică",
          "redeemCode": "Folosește un cod de reducere sau un card cadou",
          "remove": "Elimină",
          "save": "Plasează comanda"
        },
        "cardPayment": "Plată cu card bancar",
        "issues": "Momentan întâmpinăm probleme cu procesarea plăților pe site.\nVom remedia situația cât mai repede posibil. Te rugăm să revii\nmai târziu dacă vrei să plasezi o comandă online.\n",
        "label": {
          "cardCode": "CVV",
          "cardExpiryMonth": "Luna",
          "cardExpiryYear": "Anul",
          "cardNumber": "Număr card",
          "discountCode": "Cod"
        },
        "tdsTitle": "Confirmare",
        "title": "Metoda de plată"
      },
      "prescription": {
        "btn": {
          "save": "Continuă",
          "skip": "Adaugă mai târziu",
          "upload": "Încarcă o poză..."
        },
        "error": {
          "size": "Dimensiunea nu trebuie să depășească 5MB",
          "type": "Extensii acceptate: jpg, png, gif sau pdf"
        },
        "label": {
          "add": "ADD",
          "axis": "AXIS",
          "cylinder": "CYL",
          "pupilDistance": "Distanța pupilară (PD)",
          "sphere": "SPH"
        },
        "option": {
          "fillIn": "Completează datele din rețetă",
          "sendLater": "Adaugă mai târziu (te sunăm noi)",
          "uploadPhoto": "Adaugă o poză cu rețeta"
        },
        "title": "Adaugă rețeta"
      },
      "shipping": {
        "btn": {
          "complete": "Plasează comanda",
          "save": "Continuă"
        },
        "title": "Adaugă adresa de livrare"
      },
      "step": {
        "billing": "Facturare",
        "confirmation": "Confirmare",
        "payment": "Plată",
        "prescription": "Rețeta",
        "shipping": "Livrare"
      },
      "title": "Finalizare comandă"
    },
    "countdown": {
      "days": "zile",
      "hours": "ore",
      "minutes": "min",
      "seconds": "sec",
      "title": "Filtru PC gratuit!"
    },
    "cta": {
      "explore": {
        "end": "",
        "men": "bărbați",
        "or": "sau",
        "start": "Vezi colecția pentru",
        "women": "femei"
      }
    },
    "error": {
      "description": "Dar e ok, lucrăm la o soluție.<br> Încearcă din nou în câteva minute.",
      "title": "Ooops. Ceva nu a mers bine."
    },
    "eyeTest": {
      "btn": {
        "book": "Rezervă loc",
        "schedule": "Programează-te",
        "unavailable": "Momentan indisponibil"
      },
      "customer": {
        "title": "Datele tale personale"
      },
      "header": {
        "subtitle": "Complet gratuit și fără obligația de a cumpăra de la noi, gata în 20 de minute.",
        "title": "Consultații optometrice"
      },
      "label": {
        "email": "Email-ul tău",
        "firstName": "Prenumele",
        "lastName": "Numele",
        "phone": "Telefon"
      },
      "pageTitle": "Consultații optometrice",
      "slots": {
        "title": "Alege ziua și ora"
      },
      "step": "Pasul {current} din {max}"
    },
    "footer": {
      "label": {
        "services": "Servicii",
        "shop": "Shop"
      },
      "legal": {
        "anpc": "ANPC",
        "copyright": "&copy; {year} LUNET",
        "privacy": "Confidențialitate",
        "terms": "Termeni"
      },
      "newsletter": {
        "confirmation": "Mulțumim pentru abonare!",
        "description": "Îți trimitem cele mai noi lansări, oferte și evenimente direct pe email.",
        "email": "Adresa de email",
        "title": "Newsletter"
      },
      "stores": "Magazine"
    },
    "fys": {
      "disclaimer": "Campania se desfășoară doar pe teritoriul României, între 24 martie și 7 aprilie.\nPe 9 aprilie anunțăm finaliștii și dăm start votului. Tu decizi cine pleacă acasă cu o\npereche fresh de ochelari de la noi: intră pe pagina noastră de Instagram și votează-ți\npreferatul pe Stories, în poll-ul activ timp de 24h. Pe 10 aprilie, anunțăm cine pleacă\nacasă cu o pereche nouă de ochelari Lunet! Premiul îl poți ridica din magazinele noastre\nsau ți-l trimitem noi oriunde în țară.\n",
      "intro": {
        "description": "Postează o poză creativă cu ochelarii Lunet pe Instagram feed și poți câștiga orice\npereche de ochelari Lunet — de vedere sau de soare, cu sau fără dioptrii!\n",
        "title": "Câștigă o pereche nouă de ochelari Lunet!"
      },
      "products": {
        "title": "Abia așteptăm să vedem ce ai pregătit!"
      },
      "steps": {
        "1": "Postează o poză creativă cu ochelarii Lunet pe Instagram feed",
        "2": "Tag @luneteyewear și folosește  hashtag-ul #mylunetstory",
        "3": "Nu porți Lunet încă? Încearcă-i acasă gratis cu Home Try-On!",
        "title": "Cum participi:"
      },
      "title": "Frame your story"
    },
    "giftCard": {
      "btn": {
        "addToCart": "Adaugă în coș",
        "faq": "e-Card FAQs",
        "shopNow": "Alege e-Card"
      },
      "deliveryDate": {
        "subtitle": "Alege o zi iar noi vom trimite e-Cardul pe email în ziua respectivă.",
        "title": "Când preferi să-l trimitem?"
      },
      "header": {
        "subtitle": "Surprinde-i pe cei dragi cu un e-Card de la noi.\nPoate fi cadoul ideal pentru cineva apropriat\ndespre care știi că are nevoie de ochelari.\n",
        "title": "O idee de cadou"
      },
      "label": {
        "giftNotes": "Câteva gânduri de la tine (opțional)",
        "giftRecipient": "Numele destinatarului",
        "giftRecipientEmail": "Email-ul destinatarului",
        "giftSender": "Numele tău"
      },
      "pageTitle": "Gift Card",
      "products": {
        "title": "Alege tipul de e-Card"
      },
      "recipient": {
        "subtitle": "Spune-ne câte ceva despre persoana care va primi cadoul și transmite-i câteva gânduri bune.",
        "title": "Cui vrei să-l trimitem?"
      },
      "step": "Pasul {current} din {max}"
    },
    "help": {
      "blueLightFilter": "#### What is blue light?\nBlue light is a high-energy light that digital screens emit. We're exposed\nto it every day, especially if we work long hours in front of a computer screen\nor if we binge-watch all 9 seasons of Seinfeld in one go. And that's where\nblue light filtering glasses might help.\n#### Does blue light hurt my eyes?\nThe flickering blue light coming from digital screens casts a glare that reduces\nyour visual contrast, affecting clarity and sharpness. Besides that, increased\nexposure to blue light may be linked to eye fatigue, headaches and sleep cycle disruption.\n#### How do I protect my eyes?\nYou can reduce your blue light exposure by adding a blue light filter to a\npair of new glasses when you select the lenses for your frames.\n#### Don't wear glasses?\nNo problem. The blue light filter is also available without an eyeglass\nprescription. So now you have a good reason to wear glasses. Finally!\n",
      "giftCard": "#### Cum pot folosi e-Cardul Lunet?\nTrebuie doar să introduci codul de pe e-Card atunci când plătești comanda.\n#### Pot să returnez un e-Card?\ne-Cardurile nu pot fi returnate sau rambursate.\n#### e-Cardul meu are o dată de expirare?\nNu, deloc. E valabil pe perioadă nedeterminată, la fel ca...\nei bine, nu ne vin acum în minte alte lucruri nedeterminate în\ntimp, dar e-Cardul nostru este cu siguranță unul dintre ele.\n#### Cum funcționează e-Cardul?\nFuncționează ca și un card-cadou tipărit. Suntem în {year} iar\nhârtia și plasticul sunt depășite, așa că îl trimitem prin email.\n#### Când va fi livrat e-Cardul?\nOricând consideri tu că e momentul potrivit, noi suntem flexibili!\nDacă alegi aceeași zi - îl vom trimite într-o oră. Dacă\nalegi o altă zi - dimineața zilei alese de tine.\n",
      "homeTryOn": "#### Ce înseamnă Home Try-on?\nHome Try-on este un serviciu gratuit prin care poți\nproba până la 4 modele de ochelari la tine acasă.\n#### Care sunt pașii pentru Home Try-on?\nAlege modelele preferate, adaugă-le în coș cu ajutorul butonului \"Probează\nacasă gratuit\", completează datele personale, adresa de livrare și\nplasează comanda. De restul ne ocupăm noi.\n#### Cât timp pot proba ochelarii?\n4 zile, din momentul în care îi primești.\n#### Pot să păstrez ochelarii care îmi plac?\nNu. Modelele de Home Try-on sunt folosite exclusiv în scop\ndemonstrativ. La sfârșitul perioadei de probă va trebui să returnezi acești ochelari.\n#### Cum cumpăr ochelarii care îmi plac?\nDupă ce te-ai decis, nu trebuie decât să plasezi o comandă nouă cu modelul ales și\nopțional, să încarci rețeta oftalmologică, iar noi îți trimitem ochelarii.\n#### Ce fac dacă nu mi se potrivesc?\nNu e nicio problemă! Lucrăm constant la modele noi care sperăm să-ți placă.\nUrmărește-ne pentru a descoperi noile modele de ochelari pe care le lansăm.\nIar când ești gata să probezi, plasează o nouă comandă gratuit.\n#### Cum returnez ochelarii?\nDupă cele 4 zile de probă, un curier va lua legătura cu tine pentru a ridica\nochelarii. Tot ce trebuie să faci este să pui la loc în cutia originală\nochelarii și să folosești AWB-ul din interiorul cutiei. Dacă dorești să modifici\ndata preluării sau adresa, sună la +40 (728) 547 184. Returul este tot gratuit.\n",
      "lenses": "#### Ce tipuri de lentile oferiți?\nLentilele respectă rețeta pe care o primim de la tine, sunt subțiate,\nau protecție UV400, filtru anti-reflex și anti-zgârieturi.\n#### Ce interval de dioptrii acoperă lentilele?\nOferim lentile lentru dioptrii cuprinse între -10 și +10. Lentilele vor fi subțiate\npentru a se potrivi cu ramele alese de tine. Pentru dioptrii >6, recomandăm\nopțiunea de lentile premium.\n#### Ce sunt lentilele monofocale?\nLentilele monofocale oferă un singur tip de corecție a vederii. Aceste lentile pot\nfi folosite de persoanele cu hipermetropie (+), miopie (-) și astigmatism. Acest tip\nde lentile este cel mai folosit pentru corecția vederii.\n#### Ce sunt lentilele fără dioptrii?\nAceste lentile nu oferă corecție de vedere, ci doar protecție la praf și la lumina albastră\nprovenită de la expunerea solară directă sau de la ecranele digitale. Lentilele noastre\npentru ochelarii fără dioptrii sunt tratate cu un strat anti-reflex și anti-zgârieturi.\n#### Ce sunt lentilele premium?\nLentilele premium sunt lentile ultra-subțiate. Noi le recomandăm persoanelor cu dioptrii >4\npentru ramele metalice, respectiv >6 pentru ramele din acetat. Suplimentar, lentilele premium\nsunt tratate cu un filtru Quarz de durificare suplimentară a lentilei.\n#### Oferiți și protecție pentru calculator?\nDa, dacă petreci mult timp în fața calculatorului, poți opta și pentru un filtru\npentru lumină albastră disponibil opțional când alegi lentilele.\n#### Cum adaug rețeta pentru lentile?\nDacă optezi pentru lentile cu dioptrii, vei adăuga rețeta la final, când plasezi\ncomanda. Poți face asta sub diferite forme: fie adaugi o fotografie cu rețeta,\nfie completezi specificațiile pentru dioptrii în formularul dedicat.\n",
      "section": {
        "blueLightFilter": "Blue Light Filter",
        "giftCard": "e-Card",
        "homeTryOn": "Home Try-on",
        "lenses": "Lentile"
      },
      "title": "Info"
    },
    "homeTryOn": {
      "btn": {
        "faq": "Cum probez?"
      },
      "subtitle": "Alege {count} modele și probează-le la tine acasă, gratuit.",
      "title": "Home Try-on"
    },
    "index": {
      "cover": {
        "btn": {
          "men": "Bărbați",
          "women": "Femei"
        },
        "date": "7-21 Aprilie",
        "description": "Primăvara arată mai bine prin Havana - acum cu 20% reducere!\n",
        "discount": "-20%",
        "title": "Havana te<br/> prinde bine"
      },
      "reviews": {
        "btn": "Citește mai multe"
      },
      "services": {
        "eyeTest": "Consultații gratuite",
        "homeTryOn": "Probează acasă",
        "stores": "Magazine"
      }
    },
    "invoice": {
      "label": {
        "discount": "Reducere",
        "item": "Articol",
        "orderNumber": "Număr comandă",
        "price": "Preț",
        "total": "Total",
        "totalVat": "Total TVA",
        "vat": "TVA",
        "vatRate": "Cotă TVA"
      },
      "title": "Factura"
    },
    "jobs": {
      "btn": {
        "apply": "Aplică"
      },
      "operator": {
        "form": "https://airtable.com/appm26rZbEvalkDF0/shrrNaJwvSx8EwcvU",
        "title": "Operator comenzi"
      },
      "optometrist": {
        "form": "https://airtable.com/appm26rZbEvalkDF0/shrvQLstfYGMLgAsU",
        "title": "Optometrist"
      },
      "salesAssistant": {
        "form": "https://airtable.com/appm26rZbEvalkDF0/shr6nJMd5Ql8wBKl4",
        "title": "Asistent vânzări"
      },
      "title": "Jobs"
    },
    "journal": {
      "description": "With Lunet, our main focus is to build a fresh new visual territory and\ncustomer experience, by placing people first, and drawing inspiration\nfrom local artists and creative community.\n",
      "title": "Journal"
    },
    "lensColor": {
      "brown": "Maro",
      "greenG15": "Verde",
      "grey": "Gri"
    },
    "location": {
      "title": "Magazin {city}"
    },
    "login": {
      "btn": {
        "login": "Autentifică-te"
      },
      "label": {
        "code": "Codul primit",
        "email": "Email-ul tău"
      },
      "notice": {
        "password": "Ți-am trimis un email cu un cod de 4 cifre."
      },
      "title": "Cont"
    },
    "menu": {
      "clipOns": "Clip-on",
      "eyeTest": "Consultații gratuite",
      "glasses": "Ochelari de vedere",
      "new": "Nou!",
      "sunglasses": "Ochelari de soare"
    },
    "nav": {
      "about": "Despre",
      "account": "Cont",
      "clipOns": "Clip-on",
      "eyeTest": "Consultații gratuite",
      "giftCards": "Gift card",
      "glasses": "Vedere",
      "homeTryOn": "Home Try-on",
      "jobs": "Jobs",
      "men": "Bărbați",
      "press": "Presă",
      "stores": "Magazine",
      "sunglasses": "Soare",
      "women": "Femei"
    },
    "notification": {
      "cookies": {
        "btn": "Ok",
        "link": "Citește mai mult",
        "title": "Folosim <span class=\"underline\">cookies</span>."
      }
    },
    "order": {
      "btn": {
        "editAddress": "Modifică adresa de livrare",
        "instagram": "Urmărește-ne",
        "printInvoice": "Tipărește factura",
        "trackPackage": "Urmărește comanda"
      },
      "canceled": {
        "description": "Comanda a fost anulată în {date}. Te rugăm să ne\ncontactezi dacă ai întrebări.\n",
        "title": "Comanda a fost anulată"
      },
      "confirmed": {
        "description": "Un email de confirmare cu toate detaliile a fost trimis la\n<strong>{email}</strong>. Poți reveni la această pagină oricând ca să afli\ndetalii despre livrarea comenzii.\n",
        "title": "Am primit comanda ta"
      },
      "delivered": {
        "description": "Comanda ta a fost expediată la adresa de livrare. Dacă ai întrebări sau\nnevoie de ajutor, te rugăm să ne scrii la <strong>{email}</strong>.\n",
        "return": "La sfârșitul perioadei de probă, <strong>în {date}</strong>, un curier\nte va contacta pentru retur. Te rugăm să pui ochelarii în cutia originală\nși să folosești AWB-ul din interiorul cutiei. Dacă dorești să modifici\ndata preluării sau adresa, sună la <strong>+40 (728) 547 184</strong>.\nReturul este gratuit.\n",
        "title": "Comanda a fost expediată"
      },
      "feedback": {
        "btn": {
          "back": "Înapoi la site",
          "send": "Trimite"
        },
        "label": {
          "note": "Dacă vrei să mai adaugi ceva, scrie-ne aici",
          "sent": "Trimis!"
        },
        "title": "Mulțumim pentru feedback."
      },
      "overview": {
        "label": {
          "deliveryDate": "Data livrării",
          "giftCard": "e-Card",
          "notes": "Mesajul tău",
          "recipient": "Pentru",
          "sender": "De la",
          "shippingAddress": "Livrarea la"
        }
      },
      "pickup": {
        "description": "Gata așteptarea, gata ochelarii! Îi poți ridica din\nmagazinul nostru, oricând în timpul programului.\n",
        "title": "Comanda te așteaptă"
      },
      "shipping": {
        "btn": {
          "save": "Salvează"
        },
        "title": "Adresa de livrare"
      },
      "summary": {
        "label": {
          "autoDiscount": "Reducere",
          "discount": "Reducere ({code})",
          "free": "Gratuit",
          "hto": "Home Try-on",
          "shipping": "Livrare",
          "total": "Total"
        }
      },
      "title": "Mulțumim!",
      "virtual": {
        "description": "Un email de confirmare cu toate detaliile a fost trimis la\n<strong>{email}</strong>. Poți reveni la această pagină oricând ca să afli\ndetalii despre e-Cardul cumpărat de tine.\n",
        "title": "Am primit comanda ta"
      }
    },
    "press": {
      "description": "With Lunet, our main focus is to build a fresh new visual territory and\ncustomer experience, by placing people first, and drawing inspiration\nfrom local artists and creative community.\n",
      "title": "Presă"
    },
    "product": {
      "autoDiscount": {
        "blueLightFilter": "Gratuit în {month}",
        "lightResponsiveFilter": "Reducere {value} în {month}",
        "singleVisionLenses": "Reducere {value} în {month}."
      },
      "benefits": {
        "case": "Toc și lavetă personalizată",
        "coating": "Filtru anti-reflex și anti-zgârieturi",
        "eyeTest": "Consultații gratuite în",
        "guarantee": "Garanție 2 ani pentru ramă și lentile",
        "lenses": {
          "clipOns": "Lentile cu protecție UV",
          "glasses": "Lentile cu dioptrii incluse în preț",
          "sunglasses": "Lentile cu protecție UV"
        },
        "return": "Retur gratuit în 30 de zile",
        "shipping": "Livrare și retur gratuit în",
        "store": "magazin",
        "title": "Beneficii",
        "uv": "Protecție UV400"
      },
      "btn": {
        "addToCart": "Adaugă în coș",
        "chooseLenses": "Alege lentilele și cumpără",
        "fullHomeTryOn": "Home Try-on plin",
        "inHomeTryOn": "Ales pentru probă",
        "more": "Află mai multe",
        "nextStep": "Pasul următor",
        "reviews": "Recenzii",
        "tryAtHome": "Probează acasă gratuit"
      },
      "details": {
        "accessories": {
          "description": "Toc magnetic și lavetă personalizată, realizate din materiale reciclate.",
          "title": "Accesorii incluse"
        },
        "frame": {
          "description": "{material, select,\n  acetate {Realizată dintr-un compus din acetat de celuloză și un agent plastifiant de origine vegetală.}\n  combi {Realizată dintr-un compus din acetat de celuloză și un agent plastifiant de origine vegetală.}\n  metal {Realizată din oțel inoxidabil, rezistent la acțiunea agenților chimici sau corozivi, reciclabil.}\n}\n",
          "title": "Ramă din {material, select, acetate {acetat} metal {oțel inoxidabil} combi {acetat}}"
        },
        "guarantee": "2 ani garanție pentru<br> ramă și lentile",
        "lenses": {
          "description": "{category, select,\n  glasses {Subțiate, indiferent de mărimea sferei sau a cilindrului. Tratate cu filtru anti-reflex și anti-zgârieturi.}\n  sunglasses {Oferă protecție împotriva razelor UV. Tratate cu filtru anti-reflex și anti-zgârieturi.}\n}\n",
          "title": "Lentile {category, select, glasses {cu dioptrii incluse} sunglasses {de soare}}"
        },
        "subtitle": "Fiecare pereche de ochelari include lentile\n{category, select, glasses {cu dioptrii} sunglasses {cu protecție UV}}, toc și lavetă.\n",
        "title": "Ce primești"
      },
      "htoPopover": {
        "description": "Acest model este în stoc limitat, de aceea nu\neste disponibil momentan pentru probă.\n",
        "title": "Home Try-on indisponibil"
      },
      "label": {
        "addFilter": "Adaugă extra opțiuni",
        "clipOn": "clip-on",
        "clipOnPrice": "Prețul include doar<br> accesoriul clip-on",
        "free": "Free",
        "inStock": "În stoc.",
        "lowInStock": "Stoc limitat.",
        "noPrescription": "Nu ai nevoie de rețetă pentru<br> ochelarii fără dioptrii.",
        "onSale": "20% reducere",
        "optional": "opțional",
        "outOfStock": "Stoc epuizat.",
        "prescription": "Rețeta o vei adăuga la final,<br> când plasezi comanda.",
        "price": "Include și costul lentilelor,<br> indiferent de dioptrii",
        "selectLenses": "Alege tipul de lentile"
      },
      "pricing": {
        "brand": "Lunet",
        "costs": "Costuri",
        "description": "Realizăm designul ochelarilor și colaborăm direct cu fabricile de producție. Am eliminat\ntoți intermediarii din proces pentru a putea oferi ochelari de calitate la un preț accesibil.\n",
        "retail": "Optică tradițională"
      },
      "similar": {
        "title": "S-ar putea să-ți placă și..."
      },
      "sizes": {
        "extraLarge": "XL",
        "large": "L",
        "medium": "M",
        "small": "S"
      },
      "specs": {
        "frame": "Ramă de dimensiuni {width, select, small {mici} medium {medii} large {mari}}\ndin {material, select, acetate {acetat} metal {oțel inoxidabil} combi {acetat}}\n",
        "measurements": "Măsurile ramei sunt {measurements}",
        "title": "Dimensiuni"
      },
      "title": "{title} {variant} - Ochelari {category, select, glasses {de vedere} sunglasses {de soare} clip-ons {clip-on}}"
    },
    "products": {
      "autoDiscount": "<strong>15% reducere</strong> la <strong>2 perechi</strong> cumpărate",
      "discountCode": "<strong>Reducere {value}%</strong> cu codul <strong>{code}</strong>",
      "hto": {
        "btn": "Află mai multe",
        "description": "Alege {count} modele<br> și probează-le la tine<br> acasă, gratuit.",
        "title": "Home Try-on"
      },
      "label": {
        "archiveSale": "Archive Sale",
        "backInStock": "din nou în stoc",
        "eyeTests": "Testează-ți vederea gratuit",
        "glassesPrice": "<strong>{price}</strong>, lentile incluse",
        "lowInStock": "stoc limitat",
        "newInStock": "NOU",
        "onSale": "Sale",
        "outOfStock": "stoc epuizat",
        "price": "<strong>{price}</strong>"
      },
      "nav": {
        "men": "Bărbați",
        "women": "Femei"
      },
      "title": "Ochelari de {category, select, glasses {vedere} sunglasses {soare}}\npentru {gender, select, men {bărbați} women {femei}}\n"
    },
    "reviews": {
      "description": "Evaluarea generală este de <strong>{nps} / 5</strong> din {total} recenzii primite.",
      "label": {
        "date": "Recenzie din {date}",
        "homeTryOn": "Home Try-on"
      },
      "title": "Recenzii clienți"
    },
    "shop": {
      "description": "Romanian-based eyewear label that brings out the best in shopping for a pair of eyeglasses.",
      "name": "Lunet Eyewear"
    },
    "shops": {
      "pageTitle": "Țara de livrare",
      "title": "Alege țara de livrare"
    },
    "stores": {
      "btn": {
        "book": "Consult gratuit",
        "callUs": "Sună-ne",
        "viewMap": "Hartă locație"
      },
      "label": {
        "contact": "Contact",
        "openNow": "Deschis azi până la {endHour}",
        "openToday": "Deschis azi de la {startHour}",
        "opensAt": "Deschis {weekday} de la {startHour}",
        "schedule": "Program",
        "services": "Servicii"
      },
      "subtitle": "O atmosferă relaxantă și plăcută în care poți să probezi toată colecția de ochelari.",
      "title": "Magazine"
    },
    "surcharge": {
      "blueLightFilter": {
        "description": "Protejează împotriva luminii albastre a ecranelor și combate oboseala ochilor.",
        "title": "Filtru protecție calculator"
      },
      "clipOn": {
        "title": "Clip-on"
      },
      "degressiveLenses": {
        "description": "Aceste lentile sunt pentru rețete cu hipermetropie (+) miopie (-).",
        "title": "Lentile degresive"
      },
      "highIndex": {
        "description": "Lentile ultra-subțiate, tratate cu filtru Quartz, recomandate în cazul dioptriilor peste -/+4.",
        "title": "Lentile premium"
      },
      "lightResponsiveFilter": {
        "description": "Cu un grad de închidere de până la 85%, este recomandat în cazuri de sensibilitate crescută la lumină.",
        "title": "Filtru heliomat"
      },
      "noPrescription": {
        "description": "Aceste lentile nu necesită rețetă, sunt doar pentru protecție.",
        "title": "Fără dioptrii"
      },
      "polarizingFilter": {
        "description": "Reduce reflexiile și strălucirea suprafețelor, fiind recomandat și pentru condus.",
        "title": "Filtru polarizare"
      },
      "progressiveLenses": {
        "description": "Aceste lentile sunt pentru rețete cu hipermetropie (+) miopie (-).",
        "title": "Lentile progresive"
      },
      "singleVisionLenses": {
        "description": "Pentru rețete cu hipermetropie (+), miopie (-) și astigmatism (cilindru).",
        "title": "Lentile monofocale"
      }
    }
  };
  _exports.default = _default;
});