define("shop/models/product", ["exports", "ember-data", "dayjs"], function (_exports, _emberData, _dayjs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo,
      hasMany = _emberData.default.hasMany;
  var LOW_STOCK_THRESHOLD = 3;

  var _default = Model.extend({
    title: attr('string'),
    variant: attr('string'),
    sku: attr('string'),
    stock: attr('number', {
      defaultValue: 0
    }),
    sold: attr('number', {
      defaultValue: 0
    }),
    available: attr('number', {
      defaultValue: 0
    }),
    inHto: attr('number', {
      defaultValue: 0
    }),
    price: attr('number', {
      defaultValue: 0
    }),
    exchangedPrice: attr('number', {
      defaultValue: 0
    }),
    position: attr('number', {
      defaultValue: 0
    }),
    nps: attr('number', {
      defaultValue: 0
    }),
    descriptionRo: attr('string'),
    descriptionEn: attr('string'),
    material: attr('string'),
    shape: attr('string'),
    width: attr('string'),
    lensColor: attr('string'),
    measurements: attr('string'),
    createdAt: attr('date'),
    updatedAt: attr('date'),
    tags: attr('raw', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    variants: attr('raw', {
      defaultValue: function defaultValue() {
        return [];
      }
    }),
    category: belongsTo('category'),
    images: hasMany('image'),
    linkedProducts: hasMany('product', {
      inverse: null
    }),
    categorySlug: Ember.computed.readOnly('category.slug'),
    categoryId: Ember.computed.readOnly('category.id'),
    imageSorting: Ember.A(['position:asc']),
    sortedImages: Ember.computed.sort('images', 'imageSorting'),
    mainImages: Ember.computed.filterBy('sortedImages', 'isMain'),
    image: Ember.computed.readOnly('mainImages.firstObject'),
    plungedImages: Ember.computed.filterBy('images', 'isPlunged'),
    plungedImage: Ember.computed.readOnly('plungedImages.firstObject'),
    womenImages: Ember.computed.filterBy('images', 'isWomen'),
    womenImage: Ember.computed.readOnly('womenImages.firstObject'),
    menImages: Ember.computed.filterBy('images', 'isMen'),
    menImage: Ember.computed.readOnly('menImages.firstObject'),
    isOutOfStock: Ember.computed.lte('available', 2),
    isGlasses: Ember.computed.readOnly('category.isGlasses'),
    isSunglasses: Ember.computed.readOnly('category.isSunglasses'),
    isClipOn: Ember.computed.readOnly('category.isClipOns'),
    hasSurcharges: Ember.computed.or('isGlasses', 'isSunglasses'),
    hasClipOn: Ember.computed.bool('clipOn'),
    hasMetalFrame: Ember.computed.equal('material', 'Metal'),
    onSale: false,
    isLowInStock: Ember.computed('available', 'inHto', function () {
      return this.available <= this.inHto + LOW_STOCK_THRESHOLD;
    }),
    clipOn: Ember.computed('linkedProducts.@each.isClipOn', function () {
      return (this.linkedProducts || []).findBy('isClipOn');
    }),
    titleWithSize: Ember.computed('title', 'isSize', 'width', function () {
      return [this.title, this.isSize && this.width].filter(function (t) {
        return t;
      }).join(' ');
    }),
    titleSlug: Ember.computed('titleWithSize', function () {
      return this.titleWithSize && this.titleWithSize.dasherize();
    }),
    variantSlug: Ember.computed('variant', function () {
      return this.variant && this.variant.dasherize();
    }),
    slug: Ember.computed('titleSlug', 'variantSlug', function () {
      return "".concat(this.titleSlug, "/").concat(this.variantSlug);
    }),
    hasHomeTryOn: Ember.computed('tags.[]', function () {
      return (this.tags || []).includes('hto');
    }),
    isFeatured: Ember.computed('tags.[]', function () {
      return (this.tags || []).includes('featured');
    }),
    isSize: Ember.computed('tags.[]', function () {
      return (this.tags || []).includes('size');
    }),
    isPublic: Ember.computed('tags.[]', function () {
      return !(this.tags || []).includes('private');
    }),
    isArchive: Ember.computed('tags.[]', function () {
      return (this.tags || []).includes('archive');
    }),
    isOnSale: Ember.computed('tags.[]', function () {
      return (this.tags || []).includes('sale');
    }),
    isNewInStock: Ember.computed('createdAt', function () {
      return (0, _dayjs.default)(this.createdAt).isAfter((0, _dayjs.default)().subtract(4, 'month'));
    }),
    isBackInStock: Ember.computed('tags.[]', function () {
      return (this.tags || []).includes('restock');
    }),
    sortedVariants: Ember.computed('variants.[]', function () {
      return (this.variants || []).sort();
    }),
    size: Ember.computed('measurements', function () {
      var size = (this.measurements || '').split('-');
      return {
        lens: size[0],
        bridge: size[1],
        temple: size[2]
      };
    })
  });

  _exports.default = _default;
});